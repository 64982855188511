import WPhoto_DoubleBorderPhotoComponent from '@wix/thunderbolt-elements/src/components/WPhoto/viewer/skinComps/BasicWPhoto/DoubleBorderPhoto.skin';
import WPhoto_DoubleBorderPhotoController from '@wix/thunderbolt-elements/src/components/WPhoto/viewer/WPhoto.controller';


const WPhoto_DoubleBorderPhoto = {
  component: WPhoto_DoubleBorderPhotoComponent,
  controller: WPhoto_DoubleBorderPhotoController
};


export const components = {
  ['WPhoto_DoubleBorderPhoto']: WPhoto_DoubleBorderPhoto
};

